"use client";

import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";

export default function ClientToastContainer() {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  return (
    <ToastContainer
      pauseOnFocusLoss={false}
      position="bottom-right"
      autoClose={1500}
    />
  );
}
