import(/* webpackMode: "eager" */ "/app/apps/platform/src/app/(main)/components/client-toast-container.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.3_@babel+core@7.25.9_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.3_@babel+core@7.25.9_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.3_@babel+core@7.25.9_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Plus_Jakarta_Sans\",\"arguments\":[{\"subsets\":[\"vietnamese\",\"latin-ext\",\"latin\"],\"display\":\"auto\"}],\"variableName\":\"plusJakartaSans\"}");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/globals.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/leaflet@1.9.4/node_modules/leaflet/dist/leaflet.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-toastify@10.0.6_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/react-toastify/dist/ReactToastify.css");
